<template>
  <b-modal
    id="pic-popup"
    v-model="picPopup"
    centered
    hide-footer
    size="xl"
    content-class="border-0 rounded-0"
    body-class="pb-5"
    header-class="border-bottom-0 pt-2 pb-2 mt-3"
    title="Crop profile picture"
    no-close-on-backdrop
    @hide="resetModal"
  >
    <vue-croppie
      ref="croppieRef"
      :enableOrientation="true"
      :boundary="boundary"
      :viewport="viewport"
    />
    <b-button @click="crop()">
      Crop
    </b-button>
  </b-modal>
</template>
<script>
export default {
    props: {
        picPopup:{
            type:Boolean,
            default:false
        },
    },
    data() {
        return {
            croppieImage: "",
            cropped: null,
            croppedImg: "",
            boundary: { width: 975, height: 350 },
            viewport: { width: 975, height: 350 },
            size: { width: 975, height: 350 },
            enforceBoundary: true,
        };
    },
    methods: {
        crop() {
            this.$bvModal.hide('pic-popup');
            let options = {
                type: 'blob',
                size: { width: 975, height: 350 },
                format: 'jpeg'
            };
            this.$refs.croppieRef.result(options, output => {
                this.cropped = output;
                this.croppedImg = URL.createObjectURL(this.cropped);
                const profile_picture = this.blobToFile(this.cropped, 'jpeg');
                this.$emit('cropProfilePhoto', profile_picture);
            });
            
            
            
        },
        blobToFile(theBlob, fileName){
            //get file from blob
            theBlob.lastModifiedDate = new Date();
            theBlob.name = fileName;
            return theBlob;
        },
        bindFile(selectedFile){
            this.$nextTick(() => {
                this.$refs.croppieRef.bind({
                    url: selectedFile
                });
            });
            
        },
        resetModal() {
            this.$emit('closePicPopup');
        }
    }
};
</script>
