<template>
  <section
    class="mx-0 w-100"
    style="max-width: 100%"
  >
    <LoadingSpinner v-if="loading" />
    <crop 
      ref="cropRef" 
      :picPopup="picPopup"
      :editImage="editImage"
      @cropProfilePhoto="updateSelectedProfPic" 
      @closePicPopup="resetProfilePic"
    />
    <crop-cover 
      ref="cropCoverRef" 
      :picPopup="coverPicPopup"
      @cropProfilePhoto="updateSelectedCoverPic" 
      @closePicPopup="resetCoverPic"
    />
    <div
      :class="[style['profile-cover'], 'w-100']"
      :style="[
        {
          backgroundImage: `url(${profileCoverFile})`,
        },
        'max-width: 100%',
      ]"
    >
      <input
        v-if="fabId === userId"
        id="profile-cover-upload"
        type="file"
        style="display: none"
        accept=".jpg, .jpeg, .png"
        @change="handleCoverChange"
      >
      <label
        v-if="fabId === userId"
        for="profile-cover-upload"
        class="profile-cover-upload"
      >
        Change cover photo</label>
      <div
        :class="[style['profile-pic'], 'rounded-circle border border-white profile-photo']"
        :style="{
          backgroundImage: `url(${profilePicFile}), url(${API_IMAGE_URL})`,
        }"
      >
        <input
          v-if="fabId === userId"
          id="profile-upload"
          type="file"
          style="display: none"
          accept=".jpg, .jpeg, .png"
          @change="handleProfilePicChange"
        >
        <label
          v-if="fabId === userId"
          for="profile-upload"
          class="profile-upload"
        >
          Change profile photo</label>
      </div>
    </div>
  </section>
</template>

<script>
import style from "../../styles/feed.module.scss";
import axios from "axios";
import { API_BASE_URL } from "../../config/config";
import { uploadToBucket, _sendResponse } from "../../js/common-function";
import { APIUrlList } from "../../js/api_url";
import LoadingSpinner from "../Common/LoadingSpinner";
import { API_IMAGE_URL } from "../../config/config";
import Crop from "../Common/Crop.vue";
import CropCover from "../Common/CropCover.vue";
export default {
    components: { LoadingSpinner, Crop, CropCover },
    props: {
        userId: {
            type: [Number, String],
            default: 0,
        },
        fabId: {
            type: [Number, String],
            default: 0,
        },
        profileCover: {
            type: String,
            required: true,
        },
        profilePic: {
            type: String,
            required: true,
        },
        getPostList: {
            type: Function,
            default: () => {},
        },
    },
    data() {
        return {
            style,
            profilePicFile: this.profilePic,
            profileCoverFile: this.profileCover,
            account_type: localStorage.getItem("account-type"),
            loading: false,
            data: null,
            API_IMAGE_URL,
            picPopup: false,
            editImage: true,
            uncroppedFile: "",
            coverPicPopup: false
        };
    },
    watch: {
        profileCover: function (newVal) {
            this.profileCoverFile = newVal;
        },
        profilePic: function (newVal) {
            this.profilePicFile = newVal;
        },
    },
    methods: {
        async handleCoverChange(event) {
            var checkfile = await this.profileCheck(event.target.files[0]);

            if (checkfile === false) {
                _sendResponse(
                    "error",
                    "Invalid cover photo: The cover photo must be a .jpeg, .jpg or .png file."
                );
                return false;
            }

            this.coverPicPopup = true;
            let cover = URL.createObjectURL(event.target.files[0]);
            this.$refs.cropCoverRef.bindFile(cover);
            
        },
        async uploadCoverPhoto(pic){
            this.loading = true;

            this.profileCoverFile = URL.createObjectURL(pic);
            var cover_image = await uploadToBucket(
                pic,
                "uploads/users/"
            );
            var formdata = {
                cover_image: cover_image,
                cover_image_thumbnail: cover_image,
            };

            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.getItem("user-token")}`;
            axios
                .post(API_BASE_URL + APIUrlList.CHANGEFABSETTING, formdata)
                .then(() => {
                    this.loading = false;
                    _sendResponse("success", "Cover photo changed successfully.");
                })
                .catch((error) => {
                    console.error(error);
                    // handle authentication and validation errors here
                    _sendResponse("error", error);
                    this.loading = false;
                });
        },
        async handleProfilePicChange(event) {
            var checkfile = await this.profileCheck(event.target.files[0]);

            if (checkfile === false) {
                _sendResponse(
                    "error",
                    "Invalid profile picture: The profile picture must be a .jpeg, .jpg or .png file."
                );
                return false;
            }

            this.uncroppedFile = event.target.files[0];

            this.picPopup = true;
            this.profilePicFile = event.target.files[0];
            this.profilePicUrl = URL.createObjectURL(event.target.files[0]);
            this.$refs.cropRef.bindFile(this.profilePicUrl);

            // document.getElementsByClassName('post-profile-pic')[0].src = this.profilePicFile;

            
        },
        async uploadProfilePic(pic){
            this.loading = true;
            this.profilePicFile = URL.createObjectURL(pic);

            document.getElementsByClassName("profile-photo")[0].src =
            this.profilePicFile;
            var profile_pic = await uploadToBucket(
                pic,
                "uploads/users/"
            );

            var formdata = {
                profile_pic: profile_pic,
            };
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.getItem("user-token")}`;
            axios
                .post(API_BASE_URL + APIUrlList.CHANGEPROFILEPIC, formdata)
                .then((response) => {
                    localStorage.setItem(
                        "user-image",
                        response.data.data.profile_pic_url
                    );
                    window.dispatchEvent(
                        new CustomEvent("user-image-localstorage-changed", {
                            detail: {
                                storage: localStorage.getItem("user-image"),
                            },
                        })
                    );
                    this.loading = false;
                    _sendResponse("success", "Profile picture changed successfully.");
                })
                .catch((error) => {
                    console.error(error);
                    // handle authentication and validation errors here
                    _sendResponse("error", error);
                    this.loading = false;
                });
        },
        async updateSelectedProfPic(pic){
            
            this.picPopup = false;
            this.url = URL.createObjectURL(pic);
            this.profilePicUrl = this.url;

            this.uploadProfilePic(pic);
        }, 
        async updateSelectedCoverPic(pic){
            this.coverPicPopup = false;
            this.profileCoverFile = URL.createObjectURL(pic);

            this.uploadCoverPhoto(pic);
        },
        uploadUncropped() {
            this.picPopup = false;
            this.uploadProfilePic(this.uncroppedFile);
        },
        async profileCheck(file) {
            var filetype = file.type;
            var fileextension = filetype.split("/").pop();
            var arr = ["jpg", "jpeg", "png"];

            var check = arr.includes(fileextension);

            return check;
        },
        resetProfilePic() {
            this.picPopup = false;
        },
        resetCoverPic() {
            this.coverPicPopup = false;
        }
    },
};
</script>
