<template>
  <b-container :class="['py-5', style['product-store-container']]">
    <loading-spinner v-if="loading" />
    <add-product
      :type="popupType"
      :showModal="showModal"
      :productData="productData"
      :userId="userId"
      @handleShow="handleShow($event)"
    />
    <delete-product
      :productId="prodId"
      :show="showDelete"
      @onClose="closeDeleteProduct"
      @onDelete="handleDeleteProduct"
    />
    <TimeSlot
      v-if="fabId"
      :profilePic="tempProfile"
      :show="slotPopup"
      :fabUsername="fabUsername"
      :fabId="fabId"
      @onClose="closeSlotPopup"
    />
    <template>
      <profile-cover
        :profileCover="tempCover"
        :profilePic="tempProfile"
        :fabId="fabId"
        :userId="userId"
      />
      <div :class="[style['description-wrapper'], 'd-lg-none']">
        <div class="d-flex">
          <p class="mr-5">
            {{ totalPublicPost }} public posts
          </p>
          <p class="mr-5">
            {{ totalExclusivePost }} exclusive posts
          </p>
          <p class="mr-5">
            {{ totalFollowers }} followers
          </p>
        </div>
      </div>
    </template>
    <b-row>
      <b-col
        lg="3"
        :class="['d-md-none d-lg-block', style['user-details']]"
      >
        <p :class="['font-weight-bold mt-4 text-center text-truncate']">
          {{ fullname !== "null" ? fullname : "" }}
        </p>
        <p
          :class="[
            'font-weight-bold mt-4 text-center',
            [showFullDesc ? '' : 'text-truncate'],
          ]"
        >
          {{ about_you !== "null" ? about_you : "" }}
        </p>
        <p
          v-if="about_you.length > 36"
          :class="[style['read-more-less'], 'p-0  mb-4 text-right']"
          @click="showDescription"
        >
          Read {{ showFullDesc ? "less" : "more" }}
        </p>
        <h6
          v-if="
            (account_type === '1' && fabId != '') ||
              (account_type === '2' && fabId != '' && fabId != userId)
          "
          class="text-uppercase"
        >
          Available Slots
        </h6>
        <h6
          v-else
          class="text-uppercase"
        >
          Upcoming events
        </h6>
        <ul
          v-if="
            fabUpcomingList.length > 0 ||
              Object.keys(fabUpcomingList).length > 0
          "
          :class="[style['event-list'], 'list-unstyled']"
        >
          <li
            v-for="(upcomingEvent, aIndex) in fabUpcomingList"
            :key="aIndex"
            :class="aIndex == 0 ? [style['active'], 'pl-3 mb-3'] : 'pl-3 mb-3'"
          >
            <strong class="small">
              {{ moment(aIndex).format("MM/DD") }}
            </strong>
            <div v-if="account_type === '1' && fabId != ''">
              <p
                v-for="bookslot of upcomingEvent"
                :key="bookslot.id"
                class="mb-0 font-open-sans"
              >
                <!-- {{ bookslot.start_time }}  -->
                <span v-if="bookslot.call_type == 'video call'">
                  <!-- <span
                    class="font-weight-bold small"
                  >  -->
                  {{ bookslot.start_time }}
                  <!-- </span> -->
                  video call
                </span>
                <span v-else>
                  <!--  <span
                    class="font-weight-bold small"
                  > -->
                  {{ bookslot.start_time }}
                  <!-- </span> -->
                  <span v-if="bookslot.stream_type">{{
                    stream_type == 1 ? "private" : "public"
                  }}</span>
                  live streaming
                </span>
              </p>
            </div>
            <div v-else>
              <p
                v-for="bookslot of upcomingEvent"
                :key="bookslot.id"
                class="mb-0 font-open-sans"
              >
                <template
                  v-if="
                    account_type === '1' ||
                      (account_type === '2' &&
                        bookslot.meet_with &&
                        bookslot.user_id == fabId)
                  "
                >
                  <span v-if="bookslot.call_type == 'Video Call'">
                    <span class="font-weight-bold small">
                      <!-- {{ bookslot.start_time }}  -->
                    </span>
                    {{ bookslot.name }} Video Call at {{ bookslot.start_time }}
                  </span>
                  <span v-else>
                    <!-- {{ bookslot.start_time }}  -->
                    {{ bookslot.name }} Live Streaming at
                    {{ bookslot.start_time }}</span>
                </template>
                <template
                  v-if="
                    account_type === '2' &&
                      bookslot.meet_with == undefined &&
                      bookslot.user_id == fabId
                  "
                >
                  <span v-if="bookslot.call_type == 'Video Call'">
                    <span class="font-weight-bold small">
                      <!-- {{ bookslot.start_time }}  -->
                    </span>
                    Video Call at {{ bookslot.start_time }}
                    <span v-if="bookslot.bookings_count == 1">with a fan</span>
                    <span
                      v-else-if="bookslot.bookings_count > 1"
                    >with {{ bookslot.bookings_count }} fans</span>
                  </span>
                  <span v-else>
                    <!-- {{ bookslot.start_time }}  -->
                    Live Streaming at {{ bookslot.start_time }}
                    <!-- <span
                            v-if="bookslot.name"
                          >with {{ bookslot.name }}</span> -->
                    <span v-if="bookslot.bookings_count == 1">with a fan</span>
                    <span
                      v-else-if="bookslot.bookings_count > 1"
                    >with {{ bookslot.bookings_count }} fans</span>
                  </span>
                </template>
              </p>
            </div>
          </li>
        </ul>
        <p
          v-else
          class="text-center"
        >
          No Event Available
        </p>
        <b-button
          v-if="
            (account_type === '1' && fabId != '' && fabUpcomingList != '') ||
              (account_type === '2' &&
                fabId != '' &&
                fabId != userId &&
                fabUpcomingList != '')
          "
          class="px-0 py-2 mb-4 d-md-block text-uppercase rounded-0 border-0 font-weight-bold font-open-sans bg-dark text-white w-100 text-center text-decoration-none"
          @click="showSlotPopup()"
        >
          Show More
        </b-button>
        <router-link
          v-else-if="account_type == '2'"
          class="px-2 py-2 mb-4 d-md-block text-uppercase rounded-0 border-0 font-weight-bold font-open-sans bg-dark text-white w-100 text-center text-decoration-none"
          to="/meetings"
        >
          UPDATE CALENDER
        </router-link>
      </b-col>
      <b-col
        lg="9"
        sm="12"
      >
        <b-row>
          <template v-if="products.length">
            <b-col
              v-for="(product, pIndex) in products"
              :key="`product${pIndex}`"
              cols="12"
              md="4" 
              lg="6" 
              xl="4"
              :class="['mb-3 mt-3',style['product-col']]"
            >
              <div :class="[style['store-card'], 'text-center']">
                <!-- <div>
                  <b-img
                    :id="`productImage${product.id}`"
                    style="cursor:pointer"
                    :src="product.images.length > 0 ? product.images[0].p_image: placeholderImage"
                    :alt="product.item"
                    fluid
                    class="mt-2"
                    @click="handlePurchase(product)"
                    @error="handleError(`productImage${product.id}`)"
                  />
                </div> -->
                <div
                  v-if="
                    product.images.length > 0 &&
                      product.images[0].file_type === 'image'
                  "
                  :class="[style['prod-img-wrapper'], 'pt-2 mx-4']"
                  @click="handlePurchase(product)"
                >
                  <zoom-on-hover
                    :id="`productImage${product.id}`"
                    :img-normal="
                      product.images.length > 0
                        ? product.images[0].p_image
                        : placeholderImage
                    "
                    :scale="2.6"
                    style="cursor:pointer"
                    :alt="product.item"
                    fluid
                    @error="handleError(`productImage${product.id}`)"
                  />
                </div>
                <div
                  v-else-if="
                    product.images.length > 0 &&
                      product.images[1] &&
                      product.images[1].file_type === 'image'
                  "
                  :class="[style['prod-img-wrapper'], 'pt-2 mx-4']"
                  @click="handlePurchase(product)"
                >
                  <zoom-on-hover
                    :id="`productImage${product.id}`"
                    :img-normal="
                      product.images.length > 0 && product.images[1]
                        ? product.images[1].p_image
                        : placeholderImage
                    "
                    :scale="2.6"
                    style="cursor:pointer"
                    :alt="product.item"
                    fluid
                    @error="handleError(`productImage${product.id}`)"
                  />
                </div>
                <div
                  v-else
                  :class="[style['prod-img-wrapper'], 'pt-2 mx-4']"
                >
                  <b-img
                    :id="`productImage${product.id}`"
                    style="cursor:pointer"
                    :src="placeholderImage"
                    :alt="product.item"
                    fluid
                    @click="handlePurchase(product)"
                    @error="handleError(`productImage${product.id}`)"
                  />
                </div>
                <div
                  class="text-truncate text-left mt-2"
                  style="width:95%"
                >
                  <span class="text-uppercase text-left ml-3 mb-0 mt-2">
                    {{ product.item }}
                  </span>
                </div>
                <p class="text-left ml-3 mb-0">
                  ${{ product.price }}
                </p>
                <div class="text-right">
                  <b-button
                    v-if="
                      account_type === '2' &&
                        product.status !== 3 &&
                        userId == product.user_id
                    "
                    :class="['px-3', 'text-uppercase mr-1 mb-3']"
                    pill
                    variant="secondary"
                    @click="openDeleteProduct(product.id)"
                  >
                    Delete
                  </b-button>
                  <b-button
                    v-if="account_type === '2' && userId == product.user_id"
                    variant="primary"
                    pill
                    :class="['px-3', 'text-uppercase mr-3 mb-3']"
                    :disabled="product.status !== 3 ? false : true"
                    @click="openAddProduct('edit', product)"
                  >
                    {{ product.status === 3 ? "Sold" : "Edit" }}
                  </b-button>
                  <b-button
                    v-else
                    variant="primary"
                    pill
                    :class="['px-3', 'text-uppercase mr-3 mb-3']"
                    @click="handlePurchase(product)"
                  >
                    Buy now
                  </b-button>
                </div>
              </div>
            </b-col>
          </template>
          <b-col
            v-if="products.length < 15 && account_type === '2'"
            :class="['mb-3 mt-3',style['product-col']]"
            cols="12"
            md="4" 
            lg="6" 
            xl="4"
          >
            <div :class="[style['store-card'], 'text-center h-100']">
              <div class="text-uppercase py-5 font-weight-bold">
                <p :class="[style['add-product-text'], 'mt-5']">
                  would you like to add more products?
                </p>
                <!-- <router-link
                  to="/settings"
                > -->
                <b-button
                  pill
                  variant="primary"
                  :class="['px-4 mt-5', 'text-uppercase mr-0 mb-3']"
                  @click="openAddProduct('add', null)"
                >
                  Add
                </b-button>
                <!-- </router-link> -->
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Pagination -->
    <div
      v-if="next_page_url !== null || prev_page_url !== null"
      class="d-flex float-right"
    >
      <b-button
        variant="dark"
        class="mr-2 mb-2 shadow-none rounded-0 px-3"
        :disabled="prevDisabled"
        @click="handlePreviousPage"
      >
        <!-- eslint-disable-next-line vue/no-parsing-error -->
        <span>&lt;</span>
      </b-button>
      <p
        v-for="page in totalPages"
        :key="page"
        :class="[
          pageNumber === page ? style['page-number'] : '',
          'px-2 mr-2 mt-2',
        ]"
      >
        {{ page }}
      </p>
      <b-button
        variant="dark"
        class="mr-2 mb-2 shadow-none rounded-0 px-3"
        :disabled="nextDisabled"
        @click="handleNextPage"
      >
        >
      </b-button>
    </div>
  </b-container>
</template>

<script>
import ProfileCover from "../Feed/ProfileCover.vue";
import style from "../../styles/feed.module.scss";
import LoadingSpinner from "./LoadingSpinner.vue";
import { getUserProfile, getUpcomingEventList } from "../../api/feed";
import {
    productList,
    handleNextProductPage,
    deleteProduct,
} from "../../api/settings";
import DeleteProduct from "../Settings/DeleteProduct";
import AddProduct from "../Settings/AddProduct.vue";
import placeholderImage from "../../assets/images/img-prod-placeholder.png";
import { dateFormat, timeFormat } from "../../js/common-function";
import axios from "axios";
import moment from "moment-timezone";
import { _sendResponse } from "../../js/common-function";
import { APIUrlList } from "../../js/api_url";
import { API_BASE_URL } from "../../config/config";
import TimeSlot from "../Feed/TimeSlot";
export default {
    components: {
        ProfileCover,
        LoadingSpinner,
        AddProduct,
        DeleteProduct,
        TimeSlot,
    },
    data() {
        return {
            style,
            loading: false,
            show: false,
            username: localStorage.getItem("username"),
            fullname: "",
            productId:
        this.$route.params.id !== undefined
            ? window.atob(this.$route.params.id)
            : 0,
            userId: localStorage.getItem("userId"),
            fabId: localStorage.getItem("fabId"),
            profile: localStorage.getItem("user-image"),
            tempCover: "",
            tempProfile: "",
            account_type: localStorage.getItem("account-type"),
            window: {
                width: 0,
                height: 0,
            },
            posts: [],
            products: [],
            totalPublicPost: 0,
            totalExclusivePost: 0,
            totalFollowers: 0,
            totalPages: [],
            advertisements: [],
            about_you: "",
            scrolledToBottom: false,
            pageRefresh: false,
            counter: 1,
            fabUpcomingList: [],
            eventDateFormat: dateFormat,
            eventTimeFormat: timeFormat,
            postId: 0,
            next_page: "",
            next_page_load: true,
            next_page_url: "",
            prev_page_url: "",
            showPopup: false,
            postData: {},
            updatePostIndex: 0,
            isfollowing: 0,
            deleteType: 0,
            pageNumber: 1,
            showModal: false,
            productData: {},
            isDisabled: false,
            last_page: 1,
            fabUsername: localStorage.getItem("fabUsername"),
            placeholderImage,
            prodId: "",
            showDelete: false,
            slotPopup: false,
            popupType: "add",
            showFullDesc: false,
        };
    },
    computed: {
        prevDisabled() {
            let disable = false;
            if (!this.prev_page_url) {
                disable = true;
            }
            return disable;
        },
        nextDisabled() {
            let disable = false;
            if (!this.next_page_url) {
                disable = true;
            }
            return disable;
        },
    },
    async created() {
        this.loading = true;
        if (document.getElementById("newsFeed") !== null) {
            document
                .getElementById("newsFeed")
                .firstChild.classList.remove("router-link-active");
        }
        if (
            this.fabUsername !== "" &&
      this.account_type === "2" &&
      this.fabUsername === this.username
        ) {
            this.fabUsername = this.username;
        }
        let username = this.fabUsername;
        if (this.account_type === "2" && this.fabId != this.userId){
            username = this.username;
        }
        let userDetails = await getUserProfile(username);
        if (userDetails) {
            this.loading = false;
            // this.fabId = userDetails.id;
            this.fullname = `${userDetails.name} ${userDetails.surname}`;
            await getUpcomingEventList(userDetails.id);
            // this.fabUpcomingList = eventList;
            this.upcomingList();
            this.about_you = userDetails.about_you;
            this.tempCover = userDetails.cover_image;
            this.tempProfile = userDetails.profile_pic_url;

            this.isfollowing = userDetails.is_follow;

            if (userDetails.account_type === 2) {
                // this.fabId = userDetails.id;
                this.totalPublicPost = userDetails.public_post;
                this.totalExclusivePost = userDetails.exclusive_post;
                this.totalFollowers = userDetails.followers;
            }
        }
        this.loading = true;
        let userId = this.productId;
        if (this.account_type === "2" && this.fabId != this.userId){
            userId = this.userId;
        }
        let product = await productList(userId);
        if (product) {
            this.loading = false;
            this.totalPages = [];
            this.last_page = product.last_page;
            this.next_page_url = product.next_page_url;
            this.prev_page_url = product.prev_page_url;
            for (var i = 1; i <= this.last_page; i++) {
                this.totalPages.push(i);
            }
            product.data.map((value) => {
                let objToPush = {
                    id: value.id,
                    user_id: value.user_id,
                    images: value.product_images,
                    item: value.name,
                    price: value.price.toFixed(2),
                    description: value.description,
                    status: value.status,
                    video: value.product_video,
                };
                this.products.push(objToPush);
            });
        }
    },
    methods: {
        async handleNextPage() {
            this.loading = true;
            this.products = [];
            if (this.pageNumber !== this.last_page) {
                this.pageNumber = this.pageNumber + 1;
                let product = await handleNextProductPage(
                    this.next_page_url,
                    this.productId
                );
                if (product) {
                    this.loading = false;
                    this.next_page_url = product.next_page_url;
                    this.prev_page_url = product.prev_page_url;
                    product.data.map((value) => {
                        if ((this.account_type == 1 && value.status !== 3) || (this.account_type == 2 && this.userId != this.fab_id && value.status !== 3) || (this.account_type == 2 && this.userId == this.fabId && (value.status == 1 || value.status == 3))){
                            let objToPush = {
                                id: value.id,
                                user_id: value.user_id,
                                images: value.product_images,
                                item: value.name,
                                price: value.price.toFixed(2),
                                description: value.description,
                                status: value.status,
                            };
                            this.products.push(objToPush);
                        }
                    });
                }
            }
        },
        groupBy(xs, key) {
            return xs.reduce(function(rv, x) {
                (rv[x[key]] = rv[x[key]] || []).push(x);
                return rv;
            }, {});
        },
        upcomingList() {
            this.loading = true;
            var curr = new Date(); // get current date
            var start_date = moment(curr).format("MM/DD/YYYY");
            var formData = {};
            if (this.fabId) {
                formData = {
                    fab_id: this.fabId,
                    start_date: start_date,
                };
            } else {
                formData = {
                    start_date: start_date,
                };
            }
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.getItem("user-token")}`;
            axios
                .post(API_BASE_URL + APIUrlList.GETFABUPCOMINGEVENT, formData)
                .then((response) => {
                    this.loading = false;
                    // this.fabUpcomingList = response.data.data;
                    // console.log(this.fabUpcomingList);
                    var array = response.data.data;
                    const showMoreSlot = [];
                    if (array) {
                        array.map((value) => {
                            var obj = {};
                            var start_date = new Date(value.start_time.replace(/-/g, "/")); //+' UTC'
                            start_date = start_date.toString();

                            var today = moment().format("DD/MM/YY");
                            var video_call_date = moment(start_date).format("DD/MM/YY");
                            var weekDayName = moment(start_date).format("dddd");
                            if (today === video_call_date) {
                                weekDayName = "Today";
                            }

                            obj["day"] = weekDayName;
                            obj["full_date"] = moment(start_date).format("YYYY-MM-DD");
                            obj["start_time"] = moment(start_date).format("h:mm a");
                            obj["slot_id"] = value.slot_id;
                            obj["stream_type"] = value.stream_type;
                            obj["bookings_count"] = value.bookings_count;
                            if (
                                (this.account_type === "1" && value.meet_with) ||
                (this.account_type === "2" &&
                  value.meet_with &&
                  value.user_id == this.fabId)
                            ) {
                                obj["name"] =
                  value.meet_with.name + " " + value.meet_with.surname;
                            } else if (
                                this.account_type === "2" &&
                value.fanName &&
                value.fanSurname
                            ) {
                                obj["name"] = value.fanName + " " + value.fanSurname;
                            }

                            var call_type = "video call";
                            if (value.call_type == 2) {
                                call_type = "streaming";
                            }
                            obj["call_type"] = call_type;
                            obj["user_id"] = value.user_id;
                            obj["meet_with"] = value.meet_with;
                            const filter = showMoreSlot.filter(
                                (item) => item.slot_id == value.slot_id
                            );

                            if (filter.length == 0) {
                                if (this.account_type === "1" && this.fabId != "") {
                                    if (value.call_type === 2) {
                                        showMoreSlot.push(obj);
                                    } else {
                                        if (value["booking"] == null) {
                                            showMoreSlot.push(obj);
                                        }
                                    }
                                } else {
                                    showMoreSlot.push(obj);
                                }
                            }
                        });
                    }

                    var mainArr = this.groupBy(showMoreSlot, "full_date");
                    this.fabUpcomingList = mainArr;
                })
                .catch((error) => {
                    console.error(error);
                    // handle authentication and validation errors here
                    _sendResponse("error", error);
                });
        },
        async handlePreviousPage() {
            this.loading = true;
            if (this.pageNumber > 1) {
                this.pageNumber = this.pageNumber - 1;
                this.products = [];
                let product = await handleNextProductPage(
                    this.prev_page_url,
                    this.productId
                );
                if (product) {
                    this.loading = false;
                    this.next_page_url = product.next_page_url;
                    this.prev_page_url = product.prev_page_url;
                    product.data.map((value) => {
                        if ((this.account_type == 1 && value.status !== 3) || (this.account_type == 2 && this.userId != this.fab_id && value.status !== 3) || (this.account_type == 2 && this.userId == this.fabId && (value.status == 1 || value.status == 3))){
                            let objToPush = {
                                id: value.id,
                                user_id: value.user_id,
                                images: value.product_images,
                                item: value.name,
                                price: value.price.toFixed(2),
                                description: value.description,
                                status: value.status,
                            };
                            this.products.push(objToPush);
                        }
                    });
                }
            }
        },
        openAddProduct(type, data) {
            this.showModal = true;
            this.popUpType = type;
            if (data) {
                this.productData = data;
            } else if (data === null) {
                this.productData = null;
            }
        },
        handleShow() {
            this.showModal = false;
        },
        async handlePurchase(data) {
            this.$router.push(`/product/${data.id}`);
        },
        handleError(data) {
            document.getElementById(data).src = placeholderImage;
        },
        openDeleteProduct(id) {
            this.prodId = id;
            this.showDelete = true;
        },
        closeDeleteProduct() {
            this.showDelete = false;
        },
        async handleDeleteProduct() {
            this.loading = true;
            let me = this;
            let deleted = await deleteProduct(this.prodId);
            if (deleted && deleted.status) {
                this.loading = false;
                this.products.findIndex(function(current, index) {
                    if (current.id === me.prodId) {
                        me.products.splice(index, 1);
                    }
                });
            } else {
                this.loading = false;
            }
        },
        showSlotPopup() {
            this.slotPopup = true;
        },
        closeSlotPopup() {
            this.slotPopup = false;
        },
        showDescription() {
            this.showFullDesc = !this.showFullDesc;
        },
    },
};
</script>
