var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{class:['py-5', _vm.style['product-store-container']]},[(_vm.loading)?_c('loading-spinner'):_vm._e(),_c('add-product',{attrs:{"type":_vm.popupType,"showModal":_vm.showModal,"productData":_vm.productData,"userId":_vm.userId},on:{"handleShow":function($event){return _vm.handleShow($event)}}}),_c('delete-product',{attrs:{"productId":_vm.prodId,"show":_vm.showDelete},on:{"onClose":_vm.closeDeleteProduct,"onDelete":_vm.handleDeleteProduct}}),(_vm.fabId)?_c('TimeSlot',{attrs:{"profilePic":_vm.tempProfile,"show":_vm.slotPopup,"fabUsername":_vm.fabUsername,"fabId":_vm.fabId},on:{"onClose":_vm.closeSlotPopup}}):_vm._e(),[_c('profile-cover',{attrs:{"profileCover":_vm.tempCover,"profilePic":_vm.tempProfile,"fabId":_vm.fabId,"userId":_vm.userId}}),_c('div',{class:[_vm.style['description-wrapper'], 'd-lg-none']},[_c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"mr-5"},[_vm._v(" "+_vm._s(_vm.totalPublicPost)+" public posts ")]),_c('p',{staticClass:"mr-5"},[_vm._v(" "+_vm._s(_vm.totalExclusivePost)+" exclusive posts ")]),_c('p',{staticClass:"mr-5"},[_vm._v(" "+_vm._s(_vm.totalFollowers)+" followers ")])])])],_c('b-row',[_c('b-col',{class:['d-md-none d-lg-block', _vm.style['user-details']],attrs:{"lg":"3"}},[_c('p',{class:['font-weight-bold mt-4 text-center text-truncate']},[_vm._v(" "+_vm._s(_vm.fullname !== "null" ? _vm.fullname : "")+" ")]),_c('p',{class:[
          'font-weight-bold mt-4 text-center',
          [_vm.showFullDesc ? '' : 'text-truncate'] ]},[_vm._v(" "+_vm._s(_vm.about_you !== "null" ? _vm.about_you : "")+" ")]),(_vm.about_you.length > 36)?_c('p',{class:[_vm.style['read-more-less'], 'p-0  mb-4 text-right'],on:{"click":_vm.showDescription}},[_vm._v(" Read "+_vm._s(_vm.showFullDesc ? "less" : "more")+" ")]):_vm._e(),(
          (_vm.account_type === '1' && _vm.fabId != '') ||
            (_vm.account_type === '2' && _vm.fabId != '' && _vm.fabId != _vm.userId)
        )?_c('h6',{staticClass:"text-uppercase"},[_vm._v(" Available Slots ")]):_c('h6',{staticClass:"text-uppercase"},[_vm._v(" Upcoming events ")]),(
          _vm.fabUpcomingList.length > 0 ||
            Object.keys(_vm.fabUpcomingList).length > 0
        )?_c('ul',{class:[_vm.style['event-list'], 'list-unstyled']},_vm._l((_vm.fabUpcomingList),function(upcomingEvent,aIndex){return _c('li',{key:aIndex,class:aIndex == 0 ? [_vm.style['active'], 'pl-3 mb-3'] : 'pl-3 mb-3'},[_c('strong',{staticClass:"small"},[_vm._v(" "+_vm._s(_vm.moment(aIndex).format("MM/DD"))+" ")]),(_vm.account_type === '1' && _vm.fabId != '')?_c('div',_vm._l((upcomingEvent),function(bookslot){return _c('p',{key:bookslot.id,staticClass:"mb-0 font-open-sans"},[(bookslot.call_type == 'video call')?_c('span',[_vm._v(" "+_vm._s(bookslot.start_time)+" "),_vm._v(" video call ")]):_c('span',[_vm._v(" "+_vm._s(bookslot.start_time)+" "),(bookslot.stream_type)?_c('span',[_vm._v(_vm._s(_vm.stream_type == 1 ? "private" : "public"))]):_vm._e(),_vm._v(" live streaming ")])])}),0):_c('div',_vm._l((upcomingEvent),function(bookslot){return _c('p',{key:bookslot.id,staticClass:"mb-0 font-open-sans"},[(
                  _vm.account_type === '1' ||
                    (_vm.account_type === '2' &&
                      bookslot.meet_with &&
                      bookslot.user_id == _vm.fabId)
                )?[(bookslot.call_type == 'Video Call')?_c('span',[_c('span',{staticClass:"font-weight-bold small"}),_vm._v(" "+_vm._s(bookslot.name)+" Video Call at "+_vm._s(bookslot.start_time)+" ")]):_c('span',[_vm._v(" "+_vm._s(bookslot.name)+" Live Streaming at "+_vm._s(bookslot.start_time))])]:_vm._e(),(
                  _vm.account_type === '2' &&
                    bookslot.meet_with == undefined &&
                    bookslot.user_id == _vm.fabId
                )?[(bookslot.call_type == 'Video Call')?_c('span',[_c('span',{staticClass:"font-weight-bold small"}),_vm._v(" Video Call at "+_vm._s(bookslot.start_time)+" "),(bookslot.bookings_count == 1)?_c('span',[_vm._v("with a fan")]):(bookslot.bookings_count > 1)?_c('span',[_vm._v("with "+_vm._s(bookslot.bookings_count)+" fans")]):_vm._e()]):_c('span',[_vm._v(" Live Streaming at "+_vm._s(bookslot.start_time)+" "),(bookslot.bookings_count == 1)?_c('span',[_vm._v("with a fan")]):(bookslot.bookings_count > 1)?_c('span',[_vm._v("with "+_vm._s(bookslot.bookings_count)+" fans")]):_vm._e()])]:_vm._e()],2)}),0)])}),0):_c('p',{staticClass:"text-center"},[_vm._v(" No Event Available ")]),(
          (_vm.account_type === '1' && _vm.fabId != '' && _vm.fabUpcomingList != '') ||
            (_vm.account_type === '2' &&
              _vm.fabId != '' &&
              _vm.fabId != _vm.userId &&
              _vm.fabUpcomingList != '')
        )?_c('b-button',{staticClass:"px-0 py-2 mb-4 d-md-block text-uppercase rounded-0 border-0 font-weight-bold font-open-sans bg-dark text-white w-100 text-center text-decoration-none",on:{"click":function($event){return _vm.showSlotPopup()}}},[_vm._v(" Show More ")]):(_vm.account_type == '2')?_c('router-link',{staticClass:"px-2 py-2 mb-4 d-md-block text-uppercase rounded-0 border-0 font-weight-bold font-open-sans bg-dark text-white w-100 text-center text-decoration-none",attrs:{"to":"/meetings"}},[_vm._v(" UPDATE CALENDER ")]):_vm._e()],1),_c('b-col',{attrs:{"lg":"9","sm":"12"}},[_c('b-row',[(_vm.products.length)?_vm._l((_vm.products),function(product,pIndex){return _c('b-col',{key:("product" + pIndex),class:['mb-3 mt-3',_vm.style['product-col']],attrs:{"cols":"12","md":"4","lg":"6","xl":"4"}},[_c('div',{class:[_vm.style['store-card'], 'text-center']},[(
                  product.images.length > 0 &&
                    product.images[0].file_type === 'image'
                )?_c('div',{class:[_vm.style['prod-img-wrapper'], 'pt-2 mx-4'],on:{"click":function($event){return _vm.handlePurchase(product)}}},[_c('zoom-on-hover',{staticStyle:{"cursor":"pointer"},attrs:{"id":("productImage" + (product.id)),"img-normal":product.images.length > 0
                      ? product.images[0].p_image
                      : _vm.placeholderImage,"scale":2.6,"alt":product.item,"fluid":""},on:{"error":function($event){return _vm.handleError(("productImage" + (product.id)))}}})],1):(
                  product.images.length > 0 &&
                    product.images[1] &&
                    product.images[1].file_type === 'image'
                )?_c('div',{class:[_vm.style['prod-img-wrapper'], 'pt-2 mx-4'],on:{"click":function($event){return _vm.handlePurchase(product)}}},[_c('zoom-on-hover',{staticStyle:{"cursor":"pointer"},attrs:{"id":("productImage" + (product.id)),"img-normal":product.images.length > 0 && product.images[1]
                      ? product.images[1].p_image
                      : _vm.placeholderImage,"scale":2.6,"alt":product.item,"fluid":""},on:{"error":function($event){return _vm.handleError(("productImage" + (product.id)))}}})],1):_c('div',{class:[_vm.style['prod-img-wrapper'], 'pt-2 mx-4']},[_c('b-img',{staticStyle:{"cursor":"pointer"},attrs:{"id":("productImage" + (product.id)),"src":_vm.placeholderImage,"alt":product.item,"fluid":""},on:{"click":function($event){return _vm.handlePurchase(product)},"error":function($event){return _vm.handleError(("productImage" + (product.id)))}}})],1),_c('div',{staticClass:"text-truncate text-left mt-2",staticStyle:{"width":"95%"}},[_c('span',{staticClass:"text-uppercase text-left ml-3 mb-0 mt-2"},[_vm._v(" "+_vm._s(product.item)+" ")])]),_c('p',{staticClass:"text-left ml-3 mb-0"},[_vm._v(" $"+_vm._s(product.price)+" ")]),_c('div',{staticClass:"text-right"},[(
                    _vm.account_type === '2' &&
                      product.status !== 3 &&
                      _vm.userId == product.user_id
                  )?_c('b-button',{class:['px-3', 'text-uppercase mr-1 mb-3'],attrs:{"pill":"","variant":"secondary"},on:{"click":function($event){return _vm.openDeleteProduct(product.id)}}},[_vm._v(" Delete ")]):_vm._e(),(_vm.account_type === '2' && _vm.userId == product.user_id)?_c('b-button',{class:['px-3', 'text-uppercase mr-3 mb-3'],attrs:{"variant":"primary","pill":"","disabled":product.status !== 3 ? false : true},on:{"click":function($event){return _vm.openAddProduct('edit', product)}}},[_vm._v(" "+_vm._s(product.status === 3 ? "Sold" : "Edit")+" ")]):_c('b-button',{class:['px-3', 'text-uppercase mr-3 mb-3'],attrs:{"variant":"primary","pill":""},on:{"click":function($event){return _vm.handlePurchase(product)}}},[_vm._v(" Buy now ")])],1)])])}):_vm._e(),(_vm.products.length < 15 && _vm.account_type === '2')?_c('b-col',{class:['mb-3 mt-3',_vm.style['product-col']],attrs:{"cols":"12","md":"4","lg":"6","xl":"4"}},[_c('div',{class:[_vm.style['store-card'], 'text-center h-100']},[_c('div',{staticClass:"text-uppercase py-5 font-weight-bold"},[_c('p',{class:[_vm.style['add-product-text'], 'mt-5']},[_vm._v(" would you like to add more products? ")]),_c('b-button',{class:['px-4 mt-5', 'text-uppercase mr-0 mb-3'],attrs:{"pill":"","variant":"primary"},on:{"click":function($event){return _vm.openAddProduct('add', null)}}},[_vm._v(" Add ")])],1)])]):_vm._e()],2)],1)],1),(_vm.next_page_url !== null || _vm.prev_page_url !== null)?_c('div',{staticClass:"d-flex float-right"},[_c('b-button',{staticClass:"mr-2 mb-2 shadow-none rounded-0 px-3",attrs:{"variant":"dark","disabled":_vm.prevDisabled},on:{"click":_vm.handlePreviousPage}},[_c('span',[_vm._v("<")])]),_vm._l((_vm.totalPages),function(page){return _c('p',{key:page,class:[
        _vm.pageNumber === page ? _vm.style['page-number'] : '',
        'px-2 mr-2 mt-2' ]},[_vm._v(" "+_vm._s(page)+" ")])}),_c('b-button',{staticClass:"mr-2 mb-2 shadow-none rounded-0 px-3",attrs:{"variant":"dark","disabled":_vm.nextDisabled},on:{"click":_vm.handleNextPage}},[_vm._v(" > ")])],2):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }