<template>
  <b-modal
    id="modal-center"
    v-model="show"
    centered
    hide-footer
    size="xl"
    content-class="border-0 rounded-0"
    body-class="pb-5"
    header-class="border-bottom-0 pt-2 pb-2 mt-3"
    title="Available time slot"
    no-close-on-backdrop  
  >
    <template v-slot:modal-header="">
      <template>
        <b-row>
          <b-col md="2">
            <!-- <div
              :class="['rounded-circle']"
              style="width: 40rem;height: 7rem;"
              :style="{background: `url(${profilePicFile}); width: 40rem;height: 7rem;`,}"
            /> -->
            <b-img
              fluid
              :class="['rounded-circle']"
              :src="profilePicFile"
              style="width: 40rem;height: 7rem;"
              @error="setPlaceholderProfileImg"
            />
          </b-col>
          <b-col 
            md="6" 
            class="mt-3"
          >
            <h3 style="text-transform: capitalize;">
              {{ fabName }}
            </h3>
            <h6> - available time slot </h6>
          </b-col>
        </b-row>
      </template>
      <b-button
        type="button"
        variant="default"
        class="p-0 ml-auto"
        @click="handleClose"
      >
        <icon-close
          height="24"
          width="24"
        />
      </b-button>
    </template>
    <template v-if="Object.keys(items).length">
      <div id="app">
        <b-table-simple responsive>
          <b-tbody>
            <b-tr 
              v-for="(i, idx) in items"
              :key="idx"
            >
              <b-td>
                <span class="font-weight-bold">{{ moment(idx).format('dddd') }}</span>
                <h4 class="font-weight-bold mb-0">
                  {{ moment(idx).format('MMMM DD') }}
                </h4>
                <small :class="[style['year-text'],'font-weight-light font-open-sans']">{{ moment(idx).format('YYYY') }}</small>
              </b-td>

              <b-table-simple responsive>
                <b-tbody>
                  <b-tr 
                    v-for="s of i"
                    :key="s.id"
                  >
                    <b-td
                      class="font-weight-light font-open-sans vertical-align-center"
                      style="width:22%"
                    >
                      {{ s.start_time }} - {{ s.end_time }}
                    </b-td>
                    <b-td
                      class="font-weight-light vertical-align-center"
                      style="width: 30%;"
                    >
                      {{ s.call_duration }}
                    </b-td>
                    <b-td
                      class="font-weight-light font-open-sans vertical-align-center text-center"
                      style="width:15%"
                    >
                      {{ s.gab_remaining }}
                    </b-td>
                    <b-td class="font-weight-light vertical-align-center">
                      ${{ s.price }}
                    </b-td>
                    <b-td class="text-center"> 
                      <b-button
                        variant="primary"
                        pill
                        :class="[style['browse-fabs-button'], 'text-uppercase vertical-align-center']"
                        @click="bookSlot(s)"
                      >
                        Book Now
                      </b-button>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>
    </template>
    <template v-else>
      <div class="text-center h4">
        No slots available
      </div>
    </template>
  </b-modal>
</template>

<script>
import IconClose from "../../assets/svg-import/close.svg";
import style from '../../styles/feed.module.scss';
import { showMoreTimeSlotList } from '../../api/meeting';
import { setPlaceholderProfileImg } from "../../js/common-function";

export default {
    name: "TimeSlot",
    components: { IconClose },
    props: {
        show:{
            type:Boolean,
            default:false
        },
        profilePic: {
            type: String,
            required: true,
        },
        fabId: {
            type: [Number, String],
            required: true,
        }
    },
    data() {
        return {
            style,
            profilePicFile: this.profilePic,
            fabName: localStorage.getItem("fabName"),
            account_type: localStorage.getItem('account-type').toString(),
            fab_id: this.fabId,
            isDisabled: true,
            isLoading: false,
            items: [],
            setPlaceholderProfileImg
        };
    },
    watch:{
        profilePic:function(newVal){
            this.profilePicFile = newVal;
        }
    },
    async created() {
        this.items = await showMoreTimeSlotList(this.account_type,this.fab_id);
    },
    methods: {
        handleClose() {
            this.$emit("onClose");
        },
        bookSlot(slots){
            let endodedId = window.btoa(slots.id);
            let objToSend = {
                slotId:endodedId
            };
            this.$router.push({path:'/booking/video', query: objToSend});
        }
    },
};
</script>
